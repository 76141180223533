import React from "react";
import { useTranslation } from "react-i18next";
import AssetsImg from "../../assets/AssetsImg";
const ServicesCards = () => {
  const { t } = useTranslation();
  const servicesObj = [
    {
      id: 1,
      image: AssetsImg.LandingPages,
      title: "LandingPages",
    },
    {
      id: 2,
      image: AssetsImg.Dashboards,
      title: "Dashboards",
    },
    {
      id: 3,
      image: AssetsImg.MobileApps,
      title: "MobileApps",
    },
    {
      id: 4,
      image: AssetsImg.FormsBlogs,
      title: "FormsBlogs",
    },
    {
      id: 5,
      image: AssetsImg.GraphicDesign,
      title: "GraphicDesign",
    },
    {
      id: 6,
      image: AssetsImg.ShoppingECommerce,
      title: "ShoppingECommerce",
    },
  ];
  return (
    <div className="container cards__container">
      {servicesObj.map(({ id, image, title }) => {
        return (
          <article className={"card__item"} key={id}>
            <div className="glassBox">
              <div className="glassBox__imgBox">
                <img src={image} alt={title} />
                <h3 className="glassBox__title">{t(title)}</h3>
              </div>
            </div>
            {/* <div className="card__item-image">
                <img src={image} alt={title} />
              </div>
              <h3>{title}</h3> */}
          </article>
        );
      })}
    </div>
  );
};

export default ServicesCards;
