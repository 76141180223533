import React from "react";
import { Outlet } from "react-router-dom";
import HeaderBar from "../components/header/HeaderBar";
import { FaChevronUp } from "react-icons/fa";
import Footer from "../components/footer/Footer";
import TranslateModal from "./TranslateModal";

const Layout = (props) => {
  return (
    <>
      <HeaderBar />
      <div
        className="scroll__settings"
        // dir={props.currentLanguage === "en" ? "ltr" : "rtl"}
        dir={"rtl"}
        style={{
          display: props.visible ? "flex" : "none",
        }}
      >
        <button className="scroll__top-btn" onClick={props.scrollToTop}>
          <FaChevronUp />
        </button>
        <TranslateModal
          translate={(lng) => props.handleSiteLanguage(lng)}
          currentLanguage={props.currentLanguage}
        />
      </div>

      <Outlet />
      <Footer />
    </>
  );
};

export default Layout;
