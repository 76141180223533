import React, { useEffect, useRef, useState } from "react";
import "./rekoTag.css";
import "animate.css";
import AppPreview from "../../img/app_preview_1.png";
import AppPreview2 from "../../img/app_preview_2.png";
import AppPreview4 from "../../img/app_preview_4.png";
import PaymentPng from "../../img/icons/payment.png";
import CustomizablePng from "../../img/icons/customizable.png";
import ConceptPng from "../../img/icons/concept.png";
import SpaBeauty from "../../img/clients/spa_beauty.png";
import NewsDigitalTv from "../../img/clients/news_digital_tv.png";
import NetSportTv from "../../img/clients/net_sport_tv.png";
import GlobalTv from "../../img/clients/global_tv.png";
import CreativePhoto from "../../img/clients/creative_photo.png";
import CleaningService from "../../img/clients/cleaning_service.png";
import { AiOutlineBranches } from "react-icons/ai";
import { RiCameraLensFill } from "react-icons/ri";
import {
  BsShieldCheck,
  BsSpeedometer2,
  BsFillBarChartLineFill,
} from "react-icons/bs";
import { FiMonitor } from "react-icons/fi";
import Collapsible from "./Collapsible";

const RekoTagComponent = () => {
  const [currentFaq, setCurrentFaq] = useState(0);
  const refScrollUp = useRef();
  useEffect(() => {
    const handleScrollUp = () => {
      refScrollUp.current.scrollIntoView({ behavior: "smooth" });
    };
    handleScrollUp();
  }, []);
  return (
    <>
      <div className="page-hero-section bg-image hero-home-1" ref={refScrollUp}>
        <div className="hero-caption container">
          <div style={{ height: "100%" }}>
            <div
              className="row align-items-center h-100"
              style={{ height: "100%", alignItems: "center" }}
            >
              <div className="col-lg-6 wow fadeInUp">
                <div className="badge mb-2">
                  <span className="icon mr-1">
                    <span className="mai-globe"></span>
                  </span>
                  #2 Editor Choice App of 2020
                </div>
                <h1 className="mb-4">Manage your Finance easier</h1>
                <p className="mb-4">
                  Mobster has features to view and manage <br />
                  our finances, such as transfer, and statistics.
                </p>
                <a href="#" className="btn btn-primary rounded-pill">
                  Get App Now
                </a>
              </div>
              <div className="col-lg-6 d-none d-lg-block wow zoomIn">
                <div className="img-place mobile-preview shadow floating-animate">
                  <img src={AppPreview} alt="app preview img" />
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>

      <div className="page-section no-scroll">
        <div className="container">
          <h2 className="text-center wow fadeIn">Our Main Features</h2>
          <div className="row justify-content-center mt-5">
            <div className="col-lg-10">
              <div className="row justify-content-center">
                <div className="col-md-6 col-lg-4 py-3 wow fadeInLeft">
                  <div className="card card-body border-0 text-center shadow pt-5">
                    <div className="svg-icon mx-auto mb-4">
                      <img src={PaymentPng} alt="PaymentPng" />
                    </div>
                    <h4>Secure Payment</h4>
                    <p className="fs-small cardTxt">
                      Lorem ipsum dolor sit amet consectetur adipisicing elit.
                      Sint voluptates esse, sunt reprehenderit
                    </p>
                  </div>
                </div>
                <div className="col-md-6 col-lg-4 py-3 wow fadeInUp">
                  <div className="card card-body border-0 text-center shadow pt-5">
                    <div className="svg-icon mx-auto mb-4">
                      <img src={CustomizablePng} alt="Customizable" />
                    </div>
                    <h4>Easily Customizable</h4>
                    <p className="fs-small cardTxt">
                      Lorem ipsum dolor sit amet consectetur adipisicing elit.
                      Sint voluptates esse, sunt reprehenderit
                    </p>
                  </div>
                </div>
                <div className="col-md-6 col-lg-4 py-3 wow fadeInRight">
                  <div className="card card-body border-0 text-center shadow pt-5">
                    <div className="svg-icon mx-auto mb-4">
                      <img src={ConceptPng} alt="Concept" />
                    </div>
                    <h4>Powerful Concept</h4>
                    <p className="fs-small cardTxt">
                      Lorem ipsum dolor sit amet consectetur adipisicing elit.
                      Sint voluptates esse, sunt reprehenderit
                    </p>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>

      <div className="page-section no-scroll">
        <div className="container">
          <div className="row align-items-center">
            <div className="col-lg-7 wow fadeIn">
              <div className="img-place">
                <img src={AppPreview4} alt="app preview" />
              </div>
            </div>
            <div className="col-lg-5 pl-lg-5 wow fadeInUp info__card">
              <h2 className="mb-4">All kind of business tools integration</h2>
              <p className="mb-4">
                Lorem ipsum dolor sit amet consectetur adipisicing elit. Officia
                repellendus illo, possimus magni cumque, voluptatem et
                necessitatibus consequatur perspiciatis laborum temporibus sint
                dolorem porro, eaque quo sequi. Tempora, voluptates quibusdam?
              </p>
              <a href="#" className="btn btn-outline-primary rounded-pill">
                See Addons
              </a>
            </div>
          </div>
        </div>
      </div>

      <div className="page-section">
        <div className="container">
          <div className="row">
            <div className="col-lg-4 py-3">
              <div className="iconic-list">
                <div className="iconic-item wow fadeInUp">
                  <div className="iconic-content">
                    <h5>Powerful Features</h5>
                    <p className="fs-small">
                      Lorem ipsum dolor sit amet, consetetur sadipscing elitr,
                      sed diam nonumy eirmod tempor invidunt ut labore et dolore
                    </p>
                  </div>
                  <div className="iconic-md iconic-text bg-warning fg-white rounded-circle">
                    <AiOutlineBranches />
                  </div>
                </div>
                <div className="iconic-item wow fadeInUp">
                  <div className="iconic-content">
                    <h5>Fully Secured</h5>
                    <p className="fs-small">
                      Lorem ipsum dolor sit amet, consetetur sadipscing elitr,
                      sed diam nonumy eirmod tempor invidunt ut labore et dolore
                    </p>
                  </div>
                  <div className="iconic-md iconic-text bg-info fg-white rounded-circle">
                    <BsShieldCheck />
                  </div>
                </div>
                <div className="iconic-item wow fadeInUp">
                  <div className="iconic-content">
                    <h5>Easy Monitoring</h5>
                    <p className="fs-small">
                      Lorem ipsum dolor sit amet, consetetur sadipscing elitr,
                      sed diam nonumy eirmod tempor invidunt ut labore et dolore
                    </p>
                  </div>
                  <div className="iconic-md iconic-text bg-indigo fg-white rounded-circle">
                    <FiMonitor />
                  </div>
                </div>
              </div>
            </div>
            <div className="col-lg-4 py-3 wow zoomIn">
              <div className="img-place mobile-preview shadow">
                <img src={AppPreview2} alt="AppPreview2" />
              </div>
            </div>
            <div className="col-lg-4 py-3">
              <div className="iconic-list">
                <div className="iconic-item wow fadeInUp">
                  <div className="iconic-md iconic-text bg-warning fg-white rounded-circle">
                    <BsSpeedometer2 />
                  </div>
                  <div className="iconic-content">
                    <h5>Powerful Features</h5>
                    <p className="fs-small">
                      Lorem ipsum dolor sit amet, consetetur sadipscing elitr,
                      sed diam nonumy eirmod tempor invidunt ut labore et dolore
                    </p>
                  </div>
                </div>
                <div className="iconic-item wow fadeInUp">
                  <div className="iconic-md iconic-text bg-success fg-white rounded-circle">
                    <RiCameraLensFill />
                  </div>
                  <div className="iconic-content">
                    <h5>Fully Secured</h5>
                    <p className="fs-small">
                      Lorem ipsum dolor sit amet, consetetur sadipscing elitr,
                      sed diam nonumy eirmod tempor invidunt ut labore et dolore
                    </p>
                  </div>
                </div>
                <div className="iconic-item wow fadeInUp">
                  <div className="iconic-md iconic-text bg-indigo fg-white rounded-circle">
                    <BsFillBarChartLineFill />
                  </div>
                  <div className="iconic-content">
                    <h5>Easy Monitoring</h5>
                    <p className="fs-small">
                      Lorem ipsum dolor sit amet, consetetur sadipscing elitr,
                      sed diam nonumy eirmod tempor invidunt ut labore et dolore
                    </p>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>

      <hr />

      {/* <!-- Pricing Table --> */}
      <div className="page-section" dir="ltr">
        <div className="container">
          <div className="row justify-content-center text-center wow fadeInUp">
            <div className="col-lg-6">
              <h2 className="mb-3">
                Get awesome features, without extra charges
              </h2>
              <p className="mb-5 cardTxt">
                Lorem ipsum dolor sit amet, consectetur adipisicing elit.
                Maiores non magnam, quis aliquid dolor magni
              </p>
            </div>
          </div>
          <div className="pricing-table mt-5 wow fadeInUp">
            <div className="pricing-item active no-transform">
              <div className="pricing-header">
                <h5>Business Plan</h5>
                <h1 className="fw-normal">$49.00</h1>
              </div>
              <div className="pricing-body">
                <ul className="theme-list">
                  <li className="list-item">Push Notification</li>
                  <li className="list-item">Unlimited Bandwith</li>
                  <li className="list-item">Realtime Database</li>
                  <li className="list-item">Monthly Backup</li>
                  <li className="list-item">24/7 Support</li>
                </ul>
              </div>
              <button className="btn btn-primary">Choose Plan</button>
            </div>
            <div className="pricing-item">
              <div className="pricing-header">
                <h5>Starter Plan</h5>
                <h1 className="fw-normal">$24.00</h1>
              </div>
              <div className="pricing-body">
                <ul className="theme-list">
                  <li className="list-item">Push Notification</li>
                  <li className="list-item">Unlimited Bandwith</li>
                  <li className="list-item">Realtime Database</li>
                  <li className="list-item">Monthly Backup</li>
                  <li className="list-item">24/7 Support</li>
                </ul>
              </div>
              <button className="btn btn-secondary">Choose Plan</button>
            </div>
          </div>
        </div>
      </div>

      <hr />

      {/* <!-- FAQ --> */}
      <div className="page-section">
        <div className="container">
          <div className="row justify-content-center align-items-start">
            <div className="col-lg-5 py-3 wow fadeInUp">
              <h2 className="mb-4">
                Frequently <br />
                asked question
              </h2>
              <p>
                Lorem ipsum dolor sit amet, consectetur adipisicing elit.
                Dolore, minus id consectetur accusantium illum necessitatibus,
                non quia sit laboriosam aut libero. Maiores neque velit modi,
                mollitia nostrum alias quibusdam et.
              </p>
              <p className="fg-primary fw-medium">Need more helps?</p>
              <span className="icon mai-call-outline"></span> Contact Us
            </div>
            <div className="col-lg-7 py-3 no-scroll-x">
              <div className="accordion accordion-gap" id="accordionFAQ">
                <div className="faq__container">
                  <Collapsible
                    open={currentFaq === 0}
                    title="Payment types that won't work"
                    callbackFunction={() => setCurrentFaq(0)}
                  >
                    <p>You can't add these payment methods to Google Pay:</p>
                    <ul>
                      <li>Wire transfers</li>
                      <li>Bank transfers</li>
                      <li>Western Union</li>
                      <li>Moneygram</li>
                      <li>Virtual credit cards (VCC)</li>
                      <li>Health savings account (HSA)</li>
                      <li>Any escrow type of payment</li>
                      <li>Prepaid cards if you use automatic payments</li>
                    </ul>
                  </Collapsible>
                </div>
                <div className="faq__container">
                  <Collapsible
                    open={currentFaq === 1}
                    title="Already seeing payment methods"
                    callbackFunction={() => setCurrentFaq(1)}
                  >
                    <p>
                      New to Google Pay and already seeing payment methods? If
                      you paid for something through Google in the past, Google
                      Pay saved your card.
                    </p>
                  </Collapsible>
                </div>
                <div className="faq__container">
                  <Collapsible
                    open={currentFaq === 2}
                    title="Your transaction cannot be completed"
                    callbackFunction={() => setCurrentFaq(2)}
                  >
                    <p>
                      If you see this error message, wait 24 hours and try to
                      add your card again.
                    </p>
                  </Collapsible>
                </div>
                <div className="faq__container">
                  <Collapsible
                    open={currentFaq === 3}
                    title="Credit & Debit cards"
                    callbackFunction={() => setCurrentFaq(3)}
                  >
                    <ul>
                      <li>American Express</li>
                      <li>MasterCard</li>
                      <li>Visa</li>
                      <li>Discover (U.S. only)</li>
                      <li>JCB (Japan and U.S. only)</li>
                      <li>Visa Electron (outside U.S. only)</li>
                      <li>
                        Elo credit cards (Brazil only; Elo debit cards aren’t
                        accepted)
                      </li>
                    </ul>
                    <p>
                      The types of cards accepted by Google services vary by
                      location and product.
                    </p>
                  </Collapsible>
                </div>
                <div className="faq__container">
                  <Collapsible
                    open={currentFaq === 4}
                    title="Send money to friends or family"
                    callbackFunction={() => setCurrentFaq(4)}
                  >
                    <p>
                      This feature is only available in the US. For the US
                      territories, this is supported only if the debit card was
                      issued under a US-licensed bank. If you'd like to know in
                      advance whether your payment to or from a US territory
                      will go through, please check with your card issuing bank.
                    </p>

                    <p>
                      You can use Google Pay to send money to friends and family
                      or to someone using their email address or phone number.
                      Google Pay charges no fees.
                    </p>

                    <p>
                      If you have a problem sending money, here is a list of
                      likely reasons.
                    </p>
                  </Collapsible>
                </div>
                <div className="faq__container">
                  <Collapsible
                    open={currentFaq === 5}
                    title="How long it usually takes to send or receive money"
                    callbackFunction={() => setCurrentFaq(5)}
                  >
                    <p>
                      For the best Google Pay experience, we recommend using a
                      debit card whenever you send or receive money from friends
                      and family. With a debit card, sending and receiving money
                      is usually the fastest option.
                    </p>
                  </Collapsible>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>

      {/* <!-- Clients --> */}
      <div className="page-section">
        <div className="container">
          <div className="text-center wow fadeIn">
            <h2 className="mb-4">Our partners</h2>
            <p>
              Become a <a href="#">partners?</a>
            </p>
          </div>
          <div className="row row-cols-1 row-cols-sm-2 row-cols-md-3 row-cols-lg-5 justify-content-center align-items-center mt-5">
            <div className="p-3 wow zoomIn">
              <div className="img-place client-img">
                <img src={CleaningService} alt="CleaningService" />
              </div>
            </div>
            <div className="p-3 wow zoomIn">
              <div className="img-place client-img">
                <img src={CreativePhoto} alt="CreativePhoto" />
              </div>
            </div>
            <div className="p-3 wow zoomIn">
              <div className="img-place client-img">
                <img src={GlobalTv} alt="GlobalTv" />
              </div>
            </div>
            <div className="p-3 wow zoomIn">
              <div className="img-place client-img">
                <img src={NetSportTv} alt="NetSportTv" />
              </div>
            </div>
            <div className="p-3 wow zoomIn">
              <div className="img-place client-img">
                <img src={NewsDigitalTv} alt="NewsDigitalTv" />
              </div>
            </div>
            <div className="p-3 wow zoomIn">
              <div className="img-place client-img">
                <img src={SpaBeauty} alt="SpaBeauty" />
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default RekoTagComponent;
