import React, { useEffect, useRef, useState } from "react";
import { FaChevronDown, FaChevronUp } from "react-icons/fa";
import "./rekoTag.css";

const Collapsible = ({ open, children, title, callbackFunction }) => {
  const [isOpen, setIsOpen] = useState(open);
  const [height, setHeight] = useState(0);
  const ref = useRef(null);
  const handleFilterOpening = () => {
    setIsOpen((prev) => !prev);
    callbackFunction();
  };
  useEffect(() => {
    if (isOpen) setHeight(ref.current?.getBoundingClientRect().height);
    else setHeight(0);
  }, [isOpen]);
  return (
    <>
      <div className="faq_card">
        <div
          className={!isOpen ? "faq__card-header" : "faq__card-header open_faq"}
          onClick={handleFilterOpening}
        >
          <h5>{title}</h5>
          <button
            type="button"
            className={isOpen ? "faq_button-open" : "faq_button"}
          >
            {!isOpen ? <FaChevronDown /> : <FaChevronUp />}
          </button>
        </div>

        <div className="my-collapse" style={{ height }}>
          <div ref={ref}>
            <div>
              {isOpen && <div className="faq__content">{children}</div>}
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default Collapsible;
