import React, { useRef, useState } from "react";
import "./contact.css";
import { MdOutlineEmail } from "react-icons/md";
import { RiMessengerLine } from "react-icons/ri";
import { BsWhatsapp } from "react-icons/bs";
import emailjs from "emailjs-com";
import { useTranslation } from "react-i18next";
import BaseModal from "../../modals/BaseModal";
const Contact = () => {
  const form = useRef();
  const { t } = useTranslation();
  const [submittingForm, setSubmittingForm] = useState(false);
  const [modalVisible, setModalVisible] = useState(false);
  const [responseMsg, setResponseMsg] = useState({
    title: t("ThankYouTitle"),
    description: t("ThankYouDescription"),
    buttonTxt: t("ThankYouButton"),
  });

  const sendEmail = (e) => {
    setSubmittingForm(true);
    e.preventDefault();
    try {
      emailjs
        .sendForm(
          "service_0z97bgd",
          "template_bp5gm7n",
          form.current,
          "VE27dibX3eSqhaf06"
        )
        .then(
          (result) => {
            setResponseMsg({
              title: t("ThankYouTitle"),
              description: t("ThankYouDescription"),
              buttonTxt: t("ThankYouButton"),
            });
            setModalVisible(true);
            e.target.reset();
          },
          (error) => {
            console.warn(error);
            setResponseMsg({
              title: t("ErrorTitle"),
              description: t("ErrorDescription"),
              buttonTxt: t("ErrorButton"),
            });
            setModalVisible(true);

            //alert(error.text);
          }
        )
        .finally(() =>
          setTimeout(() => {
            setSubmittingForm(false);
          }, 2000)
        );
    } catch (e) {
      console.warn(e);
      setModalVisible(true);
      setResponseMsg({
        title: t("ErrorTitle"),
        description: t("ErrorDescription"),
        buttonTxt: t("ErrorButton"),
      });
    }
  };
  return (
    <section id="contact">
      <h5>{t("GetInTouch")}</h5>
      <h2>{t("ContactMe")}</h2>
      <div className="container contact__container">
        <div className="contact__options">
          <article className="contact__option">
            <MdOutlineEmail className="contact__option-icon" />
            <h4>{t("Email")}</h4>
            <h5>arkan.1997@gmail.com</h5>
            <a
              href="mailto:arkan.1997@gmail.com"
              rel="noreferrer"
              target="_blank"
            >
              {t("SendAMessage")}
            </a>
          </article>
          <article className="contact__option">
            <BsWhatsapp className="contact__option-icon" />
            <h4>{t("Whatsapp")}</h4>
            <h5>0524033910</h5>
            <a
              href="https://wa.me/972524033910?text=Im%20interested%20in%20your%20portfolio,%20need%20to%20listen%20more%20..."
              rel="noreferrer"
              target="_blank"
            >
              {t("SendAMessage")}
            </a>
          </article>
          <article className="contact__option">
            <RiMessengerLine className="contact__option-icon" />
            <h4>{t("Messenger")}</h4>
            <h5>Arkan Jbali</h5>
            <a href="https://m.me/arkan97j" rel="noreferrer" target="_blank">
              {t("SendAMessage")}
            </a>
          </article>
        </div>
        {/* END OF CONTACT OPTIONS */}
        <form ref={form} onSubmit={sendEmail}>
          <input
            type="text"
            name="name"
            placeholder={t("form_FullName")}
            required
          />
          <input
            type="text"
            name="phone"
            placeholder={t("form_Phone")}
            required
          />
          <input type="email" name="email" placeholder={t("form_Email")} />
          <textarea
            name="message"
            rows="7"
            placeholder={t("form_Notes")}
            required
          />
          {submittingForm ? (
            <div className="btn btn-primary loader-btn ">
              <div className="loader"></div>
            </div>
          ) : (
            <button type="submit" className="btn btn-primary">
              {t("SendMessage")}
            </button>
          )}
        </form>
      </div>
      <BaseModal visible={modalVisible} onClose={() => setModalVisible(false)}>
        <div
          style={{
            padding: 10,
            paddingLeft: 20,
            paddingRight: 20,
            alignItems: "center",
            justifyContent: "center",
            display: "flex",
            flexDirection: "column",
          }}
        >
          <h3 className="service__modal-title">{responseMsg.title}</h3>
          <p className="service__modal-description">
            {responseMsg.description}
          </p>
          <button
            className="btn btn-primary"
            onClick={() => setModalVisible(false)}
          >
            {responseMsg.buttonTxt}
          </button>
        </div>
      </BaseModal>
    </section>
  );
};

export default Contact;
