import QRCode from "../assets/qr-code.png";
import SignLogo from "../assets/signLogo.png";
import ArkanLogo from "../assets/arkan_logo.png";
import ArkanPic from "../assets/ArkanPic.png";
import Logo from "../assets/Logo.png";
import Blue_Logo from "../assets/Blue_Logo.png";
import UserAvatar from "../assets/userAvatar.png";
import Dashboards from "../assets/dashboards.png";
import FormsBlogs from "../assets/formsBlogs.png";
import GraphicDesign from "../assets/graphicDesign.png";
import LandingPages from "../assets/landingPages.png";
import MobileApps from "../assets/mobileApps.png";
import ShoppingECommerce from "../assets/shoppingEcommerce.png";

import Accountants from "../assets/projects/Accountants.png";
import BudgetToCity from "../assets/projects/BudgetToCity.png";
import Ceramica from "../assets/projects/Ceramica.png";
import Disclostore from "../assets/projects/Disclostore.png";
import Khalanj from "../assets/projects/Khalanj.png";
import Metrolize from "../assets/projects/Metrolize.png";
import Otlob from "../assets/projects/Otlob.png";
import RabbitApp from "../assets/projects/rabbit_app.png";
import RestartMarketing from "../assets/projects/restartMarketing.png";
import RestartMarketingV2 from "../assets/projects/restartMarketingV2.png";
import StreetSandwich from "../assets/projects/StreetSandwich.png";
import SuperZol from "../assets/projects/SuperZol.png";
import Travelo from "../assets/projects/Travelo.png";
import UnivScores from "../assets/projects/UnivScores.png";
import UTI from "../assets/projects/UTI.png";
import ZooPilot from "../assets/projects/ZooPilot.png";
import Dorak from "../assets/projects/Dorak.png";
import addContact from "../assets/Icons/addContact.png";
import idCard from "../assets/Icons/idCard.png";
import play from "../assets/Icons/play.png";
import qrCode from "../assets/Icons/qrCode.png";

export default {
  QRCode,
  Blue_Logo,
  Logo,
  SignLogo,
  ArkanLogo,
  ArkanPic,
  UserAvatar,
  Dashboards,
  FormsBlogs,
  GraphicDesign,
  LandingPages,
  MobileApps,
  ShoppingECommerce,
  Accountants,
  BudgetToCity,
  Ceramica,
  Disclostore,
  Khalanj,
  Metrolize,
  Otlob,
  RabbitApp,
  RestartMarketing,
  RestartMarketingV2,
  StreetSandwich,
  SuperZol,
  Travelo,
  UnivScores,
  UTI,
  ZooPilot,
  Dorak,
  addContact,
  idCard,
  play,
  qrCode,
};
