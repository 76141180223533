import React, { Suspense, useEffect, useState } from "react";
import Home from "./pages/Home";
import { BrowserRouter, Route, Routes } from "react-router-dom";
import Resume from "./pages/Resume";
// import Cookies from "js-cookie";
import i18n from "./i18n";
import NotFound from "./components/notFound/NotFound";
import RekoTag from "./pages/RekoTag";
import Layout from "./pages/Layout";
import Loader from "./components/loader/Loader";
import BusinessCard from "./components/businessCard/BusinessCard";

const App = () => {
  // const currentLocale = Cookies.get("i18next") || "en";
  const [visible, setVisible] = useState(false);
  const [currentLanguage, setCurrentLanguage] = useState(() => {
    const saved = localStorage.getItem("lang");
    return saved !== null ? saved : "he";
  });

  useEffect(() => {
    const AppMount = async () => {
      const languages = [
        { name: "العربية", code: "ar", dir: "rtl" },
        { name: "עברית", code: "he", dir: "rtl" },
        { name: "English", code: "en", dir: "lrt" },
      ];
      const currentLangObj = languages.find(
        (lang) => lang.code === currentLanguage
      );
      document.body.dir = currentLangObj?.dir ?? "rtl";
      await i18n.changeLanguage(currentLangObj?.code ?? "he");
    };
    AppMount();
  }, [currentLanguage]);
  const handleSiteLanguage = (lang) => {
    localStorage.setItem("lang", lang);
    setCurrentLanguage(lang);
  };
  const toggleVisible = () => {
    const scrolled = document.documentElement.scrollTop;
    if (scrolled > 300) {
      setVisible(true);
    } else if (scrolled <= 300) {
      setVisible(false);
    }
  };

  const scrollToTop = () => {
    window.scrollTo({
      top: 0,
      behavior: "smooth",
      /* you can also use 'auto' behaviour
         in place of 'smooth' */
    });
  };

  window.addEventListener("scroll", toggleVisible);

  return (
    <>
      <BrowserRouter>
        <Suspense fallback={<Loader />}>
          <Routes>
            <Route
              path="/"
              element={
                <Layout
                  visible={visible}
                  currentLanguage={currentLanguage}
                  scrollToTop={scrollToTop}
                  handleSiteLanguage={handleSiteLanguage}
                />
              }
            >
              <Route index element={<Home />} />
              <Route path="Resume" element={<Resume />} />
              <Route path="RekoTag" element={<RekoTag />} />
              <Route path="*" element={<NotFound />} />
            </Route>
            <Route path="BusinessCard" element={<BusinessCard />} />
          </Routes>
        </Suspense>
      </BrowserRouter>
    </>
  );
};

export default App;
