import React from "react";
import "./baseModal.css";
import { IoClose } from "react-icons/io5";
const BaseModal = (props) => {
  return (
    <div
      className={props.visible ? "modal" : "modal  modal-visibility"}
      onClick={props.backClose ? props.onClose : null}
    >
      <div className="modal-content">
        <IoClose className="modal-close" onClick={props.onClose} />
        {props.children}
      </div>
    </div>
  );
};

export default BaseModal;
