import React from "react";
import "./header.css";
import CTA from "./CTA";
import HeaderSocials from "./HeaderSocials";
import { BsMouse } from "react-icons/bs";
import { useTranslation } from "react-i18next";
import AssetsImg from "../../assets/AssetsImg";
const Header = () => {
  const { t } = useTranslation();
  return (
    <header id="headerElement">
      <div className="container header__container">
        <h5>{t("hello")}</h5>
        <h1>{t("myName")}</h1>
        {/* <h5 className="text-light">{t("fullstack")}</h5> */}
        <h3 className="text-light wiffy">
          {"Developer who capable to: Turning "}
          <span className="text-light">
            <b>{"tough"}</b>
          </span>
          <span className="text-light">{" ideas into "}</span>
          <span className="text-light">
            <b>{"powerful"}</b>
          </span>
          <span className="text-light">{" results, with "}</span>
          <span className="text-light">
            <b>{"emotional"}</b>
          </span>
          <span className="text-light">
            <b>{" impact.😊"}</b>
          </span>
        </h3>
        <CTA />
        <HeaderSocials />
        <div className="me">
          <img src={AssetsImg.Logo} alt="me" />
        </div>
        <a href="#about" className="scroll__down">
          <BsMouse className="scroll__down_mouse" />
          <span>Scroll Down</span>
        </a>
      </div>
    </header>
  );
};

export default Header;
