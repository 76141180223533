import React, { useEffect, useState } from "react";
import "./nav.css";
import { AiOutlineHome, AiOutlineUser } from "react-icons/ai";
import { BiBook, BiMessageSquareDetail } from "react-icons/bi";
import { RiServiceLine } from "react-icons/ri";

const Nav = () => {
  const [activeNav, setActiveNav] = useState("#");
  useEffect(() => {
    window.addEventListener("scroll", handleScroll);
    return () => {
      window.removeEventListener("scroll", handleScroll);
    };
  }, []);

  const handleScroll = () => {
    const headerElement = document.getElementById("headerElement");
    const aboutElement = document.getElementById("about");
    const experienceElement = document.getElementById("experience");
    const servicesElement = document.getElementById("services");
    const contactElement = document.getElementById("contact");
    let yPosition = window.pageYOffset + 100;
    if (headerElement.offsetTop <= yPosition) {
      setActiveNav("#");
    }
    if (aboutElement.offsetTop <= yPosition) {
      setActiveNav("#about");
    }
    if (experienceElement.offsetTop <= yPosition) {
      setActiveNav("#experience");
    }
    if (servicesElement.offsetTop <= yPosition) {
      setActiveNav("#services");
    }
    if (contactElement.offsetTop <= yPosition) {
      setActiveNav("#contact");
    }
  };
  const scrollTo = (to) => {
    const headerElement = document.getElementById("headerElement");
    const aboutElement = document.getElementById("about");
    const experienceElement = document.getElementById("experience");
    const servicesElement = document.getElementById("services");
    const contactElement = document.getElementById("contact");
    let offTop = headerElement.offsetTop;
    if (to === "#") {
      offTop = headerElement.offsetTop;
    } else if (to === "#about") {
      offTop = aboutElement.offsetTop - 80;
    } else if (to === "#experience") {
      offTop = experienceElement.offsetTop - 80;
    } else if (to === "#services") {
      offTop = servicesElement.offsetTop - 80;
    } else if (to === "#contact") {
      offTop = contactElement.offsetTop - 80;
    }
    window.scrollTo({ behavior: "smooth", top: offTop });
    setActiveNav(to);
  };
  return (
    <nav>
      <button
        onClick={() => scrollTo("#")}
        className={activeNav === "#" ? "active" : ""}
      >
        <AiOutlineHome />
      </button>
      <button
        onClick={() => scrollTo("#about")}
        className={activeNav === "#about" ? "active" : ""}
      >
        <AiOutlineUser />
      </button>
      <button
        onClick={() => scrollTo("#experience")}
        className={activeNav === "#experience" ? "active" : ""}
      >
        <BiBook />
      </button>
      <button
        onClick={() => scrollTo("#services")}
        className={activeNav === "#services" ? "active" : ""}
      >
        <RiServiceLine />
      </button>
      <button
        onClick={() => scrollTo("#contact")}
        className={activeNav === "#contact" ? "active" : ""}
      >
        <BiMessageSquareDetail />
      </button>
    </nav>
  );
};

export default Nav;
