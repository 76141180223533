import React from "react";
import "./footer.css";
import { BsLinkedin } from "react-icons/bs";
import { FiInstagram } from "react-icons/fi";
import { FaGithub, FaFacebookF } from "react-icons/fa";
import { useTranslation } from "react-i18next";
import { Link } from "react-router-dom";
const Footer = () => {
  const { t } = useTranslation();
  return (
    <footer className="noprint">
      <Link to="/" className="footer__logo" dir="ltr">
        {"<Arkan />"}
      </Link>
      <ul className="permalinks">
        <li>
          <Link to="/">{t("Home")}</Link>
        </li>
        <li>
          <a href="#about">{t("About")}</a>
        </li>
        <li>
          <a href="#experience">{t("Experience")}</a>
        </li>
        <li>
          <a href="#services">{t("Services")}</a>
        </li>
        <li>
          <a href="#portfolio">{t("Portfolio")}</a>
        </li>
        {/* <li>
          <a href="#testimonials">{t("Testimonials")}</a>
        </li> */}
        <li>
          <a href="#contact">{t("Contact")}</a>
        </li>
        <li>
          <Link to="/Resume">{t("resume")}</Link>
        </li>
        {/* <li>
          <Link to="/RekoTag">{"RekoTag"}</Link>
        </li> */}
      </ul>
      <div className="footer__socials" dir="ltr">
        <a
          href="https://github.com/ArkanJbali"
          rel="noreferrer"
          target="_blank"
        >
          <FaGithub />
        </a>
        <a
          href="https://www.linkedin.com/in/arkan-jbali/"
          rel="noreferrer"
          target="_blank"
        >
          <BsLinkedin />
        </a>
        <a
          href="https://www.facebook.com/arkan97j"
          rel="noreferrer"
          target="_blank"
        >
          <FaFacebookF />
        </a>
        <a
          href="https://www.instagram.com/arkanjbali/"
          rel="noreferrer"
          target="_blank"
        >
          <FiInstagram />
        </a>
      </div>

      <div className="footer__copyright" dir="ltr">
        <small>&copy; Arkan Jbali. All rights reserved </small>
      </div>
    </footer>
  );
};

export default Footer;
