import React from "react";
import AssetsImg from "../../assets/AssetsImg";
import "./header.css";
// import { MdOutlineNightsStay, MdOutlineWbSunny } from "react-icons/md";
import { Link } from "react-router-dom";
const HeaderBar = (props) => {
  // const [toggleSiteMode, setToggleSiteMode] = useState(false);

  return (
    <div className="header__bar">
      <Link to="/">
        <div className="header__logo">
          <img src={AssetsImg.Blue_Logo} alt="logo" />
        </div>
      </Link>
    </div>
  );
};

export default HeaderBar;
