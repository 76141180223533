import React from "react";
import { Link } from "react-router-dom";
// import CV from "../../assets/ArkanJbali_CV.docx";
import { useTranslation } from "react-i18next";
const CTA = () => {
  const { t } = useTranslation();
  return (
    <div className="cta">
      <Link to="/Resume" className="btn">
        {t("resume")}
      </Link>
      {/* <a href={CV} download className="btn">
        Download CV
      </a> */}
      <a href="#contact" className="btn btn-primary">
        {t("letsTalk")}
      </a>
    </div>
  );
};

export default CTA;
