import React, { useState } from "react";
import BaseModal from "../modals/BaseModal";
import { RiTranslate } from "react-icons/ri";
import { useTranslation } from "react-i18next";

//   currentLanguage: string;
//   translate: Function;
const TranslateModal = (props) => {
  const { t } = useTranslation();
  const [showTranslateModal, setShowTranslateModal] = useState(false);
  const currentLanguage = () => {
    let langWord = "";
    switch (props.currentLanguage) {
      case "en":
        langWord = "En";
        break;
      case "he":
        langWord = "עב";
        break;
      case "ar":
        langWord = "عر";
        break;
      default:
        langWord = "En";
        break;
    }
    return langWord;
  };
  const RenderLanguageButtons = () => {
    const arr = [
      { id: 0, name: "English", lng: "en" },
      { id: 1, name: "עברית", lng: "he" },
      { id: 2, name: "العربية", lng: "ar" },
    ];
    return arr.map((el) => (
      <h5
        onClick={() => {
          props.translate(el.lng);
          setTimeout(() => {
            setShowTranslateModal(false);
          }, 500);
        }}
        key={el.id}
        className={
          props.currentLanguage === el.lng
            ? "translate__button translate__button-active"
            : "translate__button"
        }
      >
        {el.name}
      </h5>
    ));
  };
  return (
    <>
      <div
        onClick={() => {
          setShowTranslateModal(true);
        }}
        className={"translate__button"}
      >
        <RiTranslate />
        <small>{currentLanguage()}</small>
      </div>
      {showTranslateModal ? (
        <BaseModal
          visible={showTranslateModal}
          onClose={() => setShowTranslateModal(false)}
        >
          <>
            <h3 className="service__modal-title">{t("translate")}</h3>
            <p className="service__modal-description">
              {`${t("current_language")} ${currentLanguage()}`}
            </p>
            <div className="translate-wrapper">
              <RenderLanguageButtons />
            </div>
          </>
        </BaseModal>
      ) : null}
    </>
  );
};

export default TranslateModal;
