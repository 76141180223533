import React, { useState } from "react";
import "./services.css";
// import { HiArrowSmRight } from "react-icons/hi";
import { IoCheckmarkSharp } from "react-icons/io5";
import { useTranslation } from "react-i18next";
import BaseModal from "../../modals/BaseModal";
import ServicesCards from "./ServicesCards";
const Services = () => {
  const { t } = useTranslation();
  const [modalVisible, setModalVisible] = useState(false);
  const [activeServicePartial, setActiveServicePartial] = useState(1);
  const openModal = (passed) => {
    setActiveServicePartial(passed);
    setModalVisible(true);
  };
  const ServicePartial = () => {
    return (
      <>
        {activeServicePartial === 1 ? (
          <>
            <h3 className="service__modal-title">{t("UiUX")}</h3>
            <p className="service__modal-description">
              {t("UiUXServiceDescription")}
            </p>
            <ul className="service__modal-list">
              <li className="service__modal-item">
                <IoCheckmarkSharp className="service__modal-icon" />
                <p className="service__modal-info">{t("UiUXService1")}</p>
              </li>
              <li className="service__modal-item">
                <IoCheckmarkSharp className="service__modal-icon" />
                <p className="service__modal-info">{t("UiUXService2")}</p>
              </li>
              <li className="service__modal-item">
                <IoCheckmarkSharp className="service__modal-icon" />
                <p className="service__modal-info">{t("UiUXService3")}</p>
              </li>
              <li className="service__modal-item">
                <IoCheckmarkSharp className="service__modal-icon" />
                <p className="service__modal-info">{t("UiUXService4")}</p>
              </li>
              <li className="service__modal-item">
                <IoCheckmarkSharp className="service__modal-icon" />
                <p className="service__modal-info">{t("UiUXService5")}</p>
              </li>
              <li className="service__modal-item">
                <IoCheckmarkSharp className="service__modal-icon" />
                <p className="service__modal-info">{t("UiUXService6")}</p>
              </li>
            </ul>
          </>
        ) : activeServicePartial === 2 ? (
          <>
            <h3 className="service__modal-title">{t("WebDevelopment")}</h3>
            <p className="service__modal-description">
              {t("WebServiceDescription")}
            </p>
            <ul className="service__modal-list">
              <li className="service__modal-item">
                <IoCheckmarkSharp className="service__modal-icon" />
                <p className="service__modal-info">{t("WebService1")}</p>
              </li>
              <li className="service__modal-item">
                <IoCheckmarkSharp className="service__modal-icon" />
                <p className="service__modal-info">{t("WebService2")}</p>
              </li>
              <li className="service__modal-item">
                <IoCheckmarkSharp className="service__modal-icon" />
                <p className="service__modal-info">{t("WebService3")}</p>
              </li>
              <li className="service__modal-item">
                <IoCheckmarkSharp className="service__modal-icon" />
                <p className="service__modal-info">{t("WebService4")}</p>
              </li>
              <li className="service__modal-item">
                <IoCheckmarkSharp className="service__modal-icon" />
                <p className="service__modal-info">{t("WebService5")}</p>
              </li>
              <li className="service__modal-item">
                <IoCheckmarkSharp className="service__modal-icon" />
                <p className="service__modal-info">{t("WebService6")}</p>
              </li>
            </ul>
          </>
        ) : activeServicePartial === 3 ? (
          <>
            <h3 className="service__modal-title">{t("MobileDevelopment")}</h3>
            <p className="service__modal-description">
              {t("MobileServiceDescription")}
            </p>
            <ul className="service__modal-list">
              <li className="service__modal-item">
                <IoCheckmarkSharp className="service__modal-icon" />
                <p className="service__modal-info">{t("MobileService1")}</p>
              </li>
              <li className="service__modal-item">
                <IoCheckmarkSharp className="service__modal-icon" />
                <p className="service__modal-info">{t("MobileService2")}</p>
              </li>
              <li className="service__modal-item">
                <IoCheckmarkSharp className="service__modal-icon" />
                <p className="service__modal-info">{t("MobileService3")}</p>
              </li>
              <li className="service__modal-item">
                <IoCheckmarkSharp className="service__modal-icon" />
                <p className="service__modal-info">{t("MobileService4")}</p>
              </li>
              <li className="service__modal-item">
                <IoCheckmarkSharp className="service__modal-icon" />
                <p className="service__modal-info">{t("MobileService5")}</p>
              </li>
              <li className="service__modal-item">
                <IoCheckmarkSharp className="service__modal-icon" />
                <p className="service__modal-info">{t("MobileService6")}</p>
              </li>
            </ul>
          </>
        ) : null}
      </>
    );
  };
  return (
    <section id="services">
      <h5>{t("WhatIOffer")}</h5>
      <h2>{t("PopularServices")}</h2>
      <div className="container services__container">
        <article className="service" onClick={() => openModal(1)}>
          <div className="service__head">
            <h3>{t("UiUX")}</h3>
            {/* <div className="service__button">
              <span>{t("SeeMore")}</span>
              <HiArrowSmRight /> 
            </div>*/}
          </div>
        </article>
        {/* END OF UI/UX */}
        <article className="service" onClick={() => openModal(2)}>
          <div className="service__head">
            <h3>{t("WebDevelopment")}</h3>
            {/* <div className="service__button">
              <span>{t("SeeMore")}</span>
               <HiArrowSmRight /> 
            </div>*/}
          </div>
        </article>
        {/* END OF Web Development */}
        <article className="service" onClick={() => openModal(3)}>
          <div className="service__head">
            <h3>{t("MobileDevelopment")}</h3>
            {/*<div className="service__button">
              <span>{t("SeeMore")}</span>
               <HiArrowSmRight /> 
            </div>*/}
          </div>
        </article>
        {/* END OF Mobile App Development */}
      </div>
      <h2>{t("Services")}</h2>
      <ServicesCards />
      <div className="container about__services">
        <p>{t("ServicesInfo")}</p>
      </div>

      <BaseModal
        visible={modalVisible}
        backClose={true}
        onClose={() => setModalVisible(false)}
      >
        <ServicePartial />
      </BaseModal>
    </section>
  );
};

export default Services;
