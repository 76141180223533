import React, { useState } from "react";
import "./portfolio.css";
import { useTranslation } from "react-i18next";
import AssetsImg from "../../assets/AssetsImg";
// import mixitup from "mixitup";
const Portfolio = () => {
  const { t } = useTranslation();
  const portfolioProjects = [
    {
      id: 1,
      image: AssetsImg.Dorak,
      title: "Dorak App",
      type: "mobile",
      github: "",
      demo: "",
    },
    {
      id: 2,
      image: AssetsImg.RestartMarketingV2,
      title: "Restart Marketing V2",
      type: "web",
      github: "",
      demo: "https://restart-marketing.co.il/",
    },
    {
      id: 3,
      image: AssetsImg.StreetSandwich,
      title: "Street Sandwich",
      type: "web",
      github: "",
      demo: "",
    },
    {
      id: 4,
      image: AssetsImg.Ceramica,
      title: "Ceramica",
      type: "mobile",
      github: "",
      demo: "",
    },
    {
      id: 5,
      image: AssetsImg.RestartMarketing,
      title: "Restart Marketing",
      type: "web",
      github: "",
      demo: "https://restart-marketing.co.il/",
    },
    {
      id: 6,
      image: AssetsImg.SuperZol,
      title: "Super Zol",
      type: "mobile",
      github: "",
      demo: "",
    },
    {
      id: 7,
      image: AssetsImg.Travelo,
      title: "Travelo",
      type: "mobile",
      github: "",
      demo: "",
    },
    {
      id: 8,
      image: AssetsImg.Otlob,
      title: "Otlob",
      type: "mobile",
      github: "",
      demo: "",
    },
  ];
  const [activeType, setActiveType] = useState("all");
  const [filteredProjects, setFilteredProjects] = useState(portfolioProjects);
  const handleActiveFilterType = (filterType) => {
    if (activeType !== filterType) {
      setActiveType(filterType);
    }
    setTimeout(() => {
      if (filterType === "all") {
        setFilteredProjects(portfolioProjects);
      } else {
        setFilteredProjects(
          portfolioProjects.filter((element) => element.type === filterType)
        );
      }
    }, 400);
  };
  // useEffect(() => {
  //   mixitup(".portfolio__container", {
  //     selectors: {
  //       target: ".portfolio__item",
  //     },
  //     animation: {
  //       effects: "fade translateZ(-100px)",
  //       duration: 400,
  //     },
  //     // data: {
  //     //   uidKey: "id",
  //     // },
  //     // load: {
  //     //   dataset: portfolioProjects,
  //     // },
  //   });
  // }, []);

  return (
    <section id="portfolio">
      <h5>{t("MyRecentWork")}</h5>
      <h2>{t("Portfolio")}</h2>
      <div className="container projects__filters">
        <span
          className={
            activeType === "all"
              ? "project__type active__type"
              : "project__type"
          }
          data-filter="all"
          onClick={() => handleActiveFilterType("all")}
        >
          {t("All_Projects")}
        </span>
        <span
          className={
            activeType === "web"
              ? "project__type active__type"
              : "project__type"
          }
          data-filter=".web"
          onClick={() => handleActiveFilterType("web")}
        >
          {t("Web_Projects")}
        </span>
        <span
          className={
            activeType === "mobile"
              ? "project__type active__type"
              : "project__type"
          }
          data-filter=".mobile"
          onClick={() => handleActiveFilterType("mobile")}
        >
          {t("Mobile_Projects")}
        </span>
        {/* <span
          className={
            activeType === "design"
              ? "project__type active__type"
              : "project__type"
          }
          data-filter=".design"
          onClick={() => handleActiveFilterType("design")}
        >
          {t("Designs_Projects")}
        </span> */}
      </div>

      <div className="container portfolio__container">
        {filteredProjects.map(({ id, image, title, github, demo, type }) => {
          return (
            <article
              className={`portfolio__item mix ${type} ${
                activeType === type || activeType === "all"
                  ? "visible"
                  : "hidden"
              }`}
              key={id}
            >
              <div className="portfolio__item-image">
                <img src={image} alt={title} />
              </div>
              <h4>{title}</h4>
              {/* <div className="portfolio__item-cta">
                {github !== "" ? (
                  <a
                    href={github}
                    className="btn"
                    rel="noreferrer"
                    target="_blank"
                  >
                    Github
                  </a>
                ) : (
                  <a
                    className="btn"
                    rel="noreferrer"
                    target="_blank"
                    role="link"
                    aria-disabled="true"
                  >
                    Github
                  </a>
                )}
                {demo !== "" ? (
                  <a
                    href={demo}
                    className="btn btn-primary"
                    rel="noreferrer"
                    target="_blank"
                  >
                    {t("LiveDemo")}
                  </a>
                ) : (
                  <a
                    className="btn btn-primary"
                    rel="noreferrer"
                    target="_blank"
                    role="link"
                    aria-disabled="true"
                  >
                    {t("LiveDemo")}
                  </a>
                )}
              </div> */}
            </article>
          );
        })}
      </div>
    </section>
  );
};

export default Portfolio;
