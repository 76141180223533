import React, { useEffect, useState } from "react";
import Header from "../components/header/Header";
import Nav from "../components/nav/Nav";
import About from "../components/about/About";
import Experience from "../components/experience/Experience";
import Services from "../components/services/Services";
import Portfolio from "../components/portfolio/Portfolio";
// import Testimonials from "../components/testimonials/Testimonials";
import Contact from "../components/contact/Contact";

const Home = () => {
  const [isNavVisible, setIsNavVisible] = useState(false);
  useEffect(() => {
    window.addEventListener("scroll", handleScroll);
    return () => {
      window.removeEventListener("scroll", handleScroll);
    };
  }, []);

  const handleScroll = () => {
    const headerElement = document.getElementById("headerElement");
    if (headerElement.getBoundingClientRect().bottom > window.pageYOffset) {
      setIsNavVisible(false);
    } else {
      setIsNavVisible(true);
    }
  };

  return (
    <div className="index_wrapper">
      <Header />
      {isNavVisible ? <Nav /> : null}
      <About />
      <Experience />
      <Services />
      <Portfolio />
      {/* <Testimonials /> */}
      <Contact />
    </div>
  );
};

export default Home;
