import React from "react";
import "./about.css";
import { FaAward } from "react-icons/fa";
import { FiUsers } from "react-icons/fi";
import { VscFolderLibrary } from "react-icons/vsc";
import { useTranslation } from "react-i18next";
import AssetsImg from "../../assets/AssetsImg";
const About = () => {
  const { t } = useTranslation();
  return (
    <section id="about">
      <h5>{t("GetToKnow")}</h5>
      <h2>{t("AboutMe")}</h2>

      <div className="container about__container">
        <div className="about__me">
          <div className="about__me-image">
            <img src={AssetsImg.ArkanPic} alt="About Portrait" />
          </div>
        </div>
        <div className="about__content">
          <div className="about__cards">
            <article className="about__card">
              <FaAward className="about__icon" />
              <h5>{t("Experience")}</h5>
              <small>2+ {t("YearsWorking")}</small>
            </article>

            <article className="about__card">
              <FiUsers className="about__icon" />
              <h5>{t("Clients")}</h5>
              <small>50+ {t("SatisfiedCustomers")}</small>
            </article>

            <article className="about__card">
              <VscFolderLibrary className="about__icon" />
              <h5>{t("Projects")}</h5>
              <small>34+ {t("Completed")}</small>
            </article>
          </div>
          <p>
            {t("aboutMeSection1")} @
            <strong className="companyLink">
              {/* <a href="https://codit.co.il/" target="blank"> */}
              CODIT Software Solutions
              {/* </a> */}
            </strong>
            💼.
            {t("aboutMeSubSection1")}
            <br />
            {t("aboutMeSection2")}
          </p>
          <a href="#contact" className="btn btn-primary">
            {t("ContactMe")}
          </a>
        </div>
      </div>
    </section>
  );
};

export default About;
