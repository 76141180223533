import React, { useState } from "react";
import HeaderBar from "../header/HeaderBar";
import "./businessCard.css";
import {
  BsChevronLeft,
  BsChevronRight,
  BsGlobe2,
  BsLinkedin,
} from "react-icons/bs";
// import { IoClose } from "react-icons/io";
import { FaFacebookF, FaGithub, FaPhoneAlt } from "react-icons/fa";
import { FiInstagram, FiMail } from "react-icons/fi";
import { MdClose, MdMessage } from "react-icons/md";
import AssetsImg from "../../assets/AssetsImg";

const BusinessCard = () => {
  // const [isIOS, setIsIOS] = useState(false);
  // const [isAndroid, setIsAndroid] = useState(false);
  const [showQrCode, setShowQrCode] = useState(false);
  const [isOpen, setIsOpen] = useState(false);
  const [currentImageIndex, setCurrentImageIndex] = useState(0);
  const images = [
    AssetsImg.RestartMarketingV2,
    AssetsImg.Dorak,
    AssetsImg.StreetSandwich,
    AssetsImg.Ceramica,
    AssetsImg.Accountants,
    AssetsImg.BudgetToCity,
    AssetsImg.Disclostore,
    AssetsImg.Khalanj,
    AssetsImg.Metrolize,
    AssetsImg.Otlob,
    // AssetsImg.RabbitApp,
    AssetsImg.RestartMarketing,
    AssetsImg.SuperZol,
    AssetsImg.Travelo,
    // AssetsImg.UnivScores,
    AssetsImg.UTI,
    // AssetsImg.ZooPilot,
  ];
  // useEffect(() => {
  //   const userAgent = navigator.userAgent || navigator.vendor || window.opera;

  //   // detect iOS
  //   if (/iPad|iPhone|iPod/.test(userAgent) && !window.MSStream) {
  //     setIsIOS(true);
  //   }

  //   // detect Android
  //   if (/android/i.test(userAgent)) {
  //     setIsAndroid(true);
  //   }
  // }, []);

  const handleClick = () => {
    window.location.href =
      "data:text/x-vcard;charset=utf-8,BEGIN%3AVCARD%0AVERSION%3A3.0%0AFN%3AArkan%20Jbali%0AN%3AJbali%3BArkan%0ATEL%3A0524033910%0AEND%3AVCARD";
    // if (isIOS) {
    //   window.location.href =
    //     "contact:?givenName=Arkan&familyName=Jbali&tel=0524033910";
    // } else if (isAndroid) {
    //   window.location.href =
    //     "data:text/x-vcard;charset=utf-8,BEGIN%3AVCARD%0AVERSION%3A3.0%0AFN%3AArkan%20Jbali%0AN%3AJbali%3BArkan%0ATEL%3A0524033910%0AEND%3AVCARD";

    //   // window.location.href =
    //   //   "tel:0524033910;vcard=BEGIN:VCARD%0AVERSION:3.0%0AFN:Arkan%20Jbali%0AN:Jbali;Arkan%0ATEL:0524033910%0AEND:VCARD";
    // } else {
    //   window.location.href =
    //     "data:text/x-vcard;charset=utf-8,BEGIN%3AVCARD%0AVERSION%3A3.0%0AFN%3AArkan%20Jbali%0AN%3AJbali%3BArkan%0ATEL%3A0524033910%0AEND%3AVCARD";
    //   alert(
    //     "Sorry, your device not supported to save information in your contacts"
    //   );
    // }
  };
  const openLightbox = (index) => {
    setIsOpen(true);
    setCurrentImageIndex(index);
  };
  const closeLightbox = () => {
    setIsOpen(false);
  };
  const moveNext = () => {
    setCurrentImageIndex((currentImageIndex + 1) % images.length);
  };
  const movePrev = () => {
    setCurrentImageIndex(
      (currentImageIndex + images.length - 1) % images.length
    );
  };
  return (
    <>
      <HeaderBar />
      {/* <!-- Begin page --> */}

      <div className="business_wrapper">
        <div className="container">
          {/* <!-- Begin Mobile Wrapper --> */}
          <div className="content_wrapper">
            <div className="overlay">
              <div className="inner_wrapper center_col_flex">
                <div className="infoCard white_shadow center_col_flex">
                  <div className="avatarWrapper"></div>
                  <h3>{"Arkan Jbali"}</h3>
                  <h5
                    className="text-light"
                    style={{ color: "#ccc", fontWeight: "500" }}
                  >
                    {"Full Stack Developer | Arkan Dev"}
                  </h5>
                  <h5 className="text-light infoQuote">
                    {
                      "Developer who capable to: Turning tough ideas into powerful results, with emotional impact. 😊"
                    }
                  </h5>

                  <div className="card_CTA">
                    <a
                      className="lgIco"
                      href="https://wa.me/972524033910?text=Im%20interested%20in%20your%20portfolio,%20need%20to%20listen%20more%20..."
                      rel="noreferrer"
                      target="_blank"
                    >
                      <MdMessage />
                    </a>
                    <a
                      className="lgIco"
                      href="tel:+972524033910"
                      rel="noreferrer"
                      target="_self"
                    >
                      <FaPhoneAlt />
                    </a>
                    <a
                      className="lgIco"
                      href="mailto:arkan.1997@gmail.com"
                      rel="noreferrer"
                      target="_self"
                    >
                      <FiMail />
                    </a>
                  </div>
                  <div className="card_CTA">
                    {/* <div className="smIco">
                      <a href="/" rel="noreferrer" target="_blank">
                        <BsGlobe2 />
                      </a>
                    </div> */}
                    <a
                      className="smIco"
                      href="https://www.facebook.com/arkan97j"
                      rel="noreferrer"
                      target="_blank"
                    >
                      <FaFacebookF />
                    </a>
                    <a
                      className="smIco"
                      href="https://www.instagram.com/arkanjbali/"
                      rel="noreferrer"
                      target="_blank"
                    >
                      <FiInstagram />
                    </a>
                    <a
                      className="smIco"
                      href="https://github.com/ArkanJbali"
                      rel="noreferrer"
                      target="_blank"
                    >
                      <FaGithub />
                    </a>
                    <a
                      className="smIco"
                      href="https://www.linkedin.com/in/arkan-jbali/"
                      rel="noreferrer"
                      target="_blank"
                    >
                      <BsLinkedin />
                    </a>
                  </div>
                </div>
                <div className="actions_row">
                  <div
                    role={"button"}
                    onClick={handleClick}
                    className="white_shadow center_col_flex btn_Action"
                  >
                    <img
                      src={AssetsImg.addContact}
                      alt={"link to save contact"}
                    />
                    <span>Save</span>
                  </div>
                  <a
                    href="/#contact"
                    className="white_shadow center_col_flex btn_Action"
                  >
                    <img
                      src={AssetsImg.play}
                      alt={"link to watch about video"}
                    />
                    <span>Watch</span>
                  </a>
                  <a
                    href="businessCard#getQuote"
                    className="white_shadow center_col_flex btn_Action"
                  >
                    <img
                      src={AssetsImg.idCard}
                      alt={"link to open about page"}
                    />
                    <span>About</span>
                  </a>
                  <div
                    role={"button"}
                    onClick={() => setShowQrCode(true)}
                    className="white_shadow center_col_flex btn_Action"
                  >
                    <img
                      src={AssetsImg.qrCode}
                      alt={"link to open qr-code to scan"}
                    />
                    <span>Scan</span>
                  </div>
                </div>
                <a
                  href="/"
                  target={"_blank"}
                  rel="noreferrer"
                  className="white_shadow web_button"
                >
                  <BsGlobe2 />
                  <span>Check Out My Website!</span>
                </a>
                <div
                  role={"button"}
                  onClick={() => openLightbox(currentImageIndex)}
                  className="white-shadow images_container_viewer"
                >
                  <img
                    src={AssetsImg.RestartMarketingV2}
                    alt={"RestartMarketingV2 Project"}
                  />
                  <img src={AssetsImg.Dorak} alt={"Dorak Project"} />
                  <img
                    src={AssetsImg.StreetSandwich}
                    alt={"StreetSandwich Project"}
                  />
                  <img src={AssetsImg.Ceramica} alt={"Ceramica Project"} />
                </div>
                {/* <div id="getQuote" style={{ paddingTop: 20 }}>
                  getQuote
                </div> */}
                {/*End Body */}
              </div>
            </div>
          </div>
          {showQrCode ? (
            <div className="lightBox_imgViewer">
              <img
                src={AssetsImg.QRCode}
                style={{ maxWidth: "90%", maxHeight: "90%" }}
                alt=""
              />
              <button
                className="imgViewer_button close_imgViewer"
                onClick={() => setShowQrCode(false)}
              >
                <MdClose />
              </button>
            </div>
          ) : null}
          {isOpen ? (
            <div className="lightBox_imgViewer">
              <img
                src={images[currentImageIndex]}
                style={{ maxWidth: "90%", maxHeight: "90%" }}
                alt=""
              />
              <button
                className="imgViewer_button prevNext_imgViewer"
                style={{ left: 20 }}
                onClick={movePrev}
              >
                <BsChevronLeft />
              </button>
              <button
                className="imgViewer_button prevNext_imgViewer"
                style={{
                  right: 20,
                }}
                onClick={moveNext}
              >
                <BsChevronRight />
              </button>
              <button
                className="imgViewer_button close_imgViewer"
                onClick={closeLightbox}
              >
                <MdClose />
              </button>
            </div>
          ) : null}
          {/* <!-- END Mobile Wrapper --> */}
        </div>
      </div>
      {/* <!-- END layout-wrapper --> */}
    </>
  );
};

export default BusinessCard;
