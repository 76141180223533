import React, { useEffect, useRef } from "react";
import "./resume.css";
import CV from "../../assets/ArkanJbali_CV.docx";
import { useTranslation } from "react-i18next";
const ResumeComponent = () => {
  const refScrollUp = useRef();
  const { t } = useTranslation();
  useEffect(() => {
    const handleScrollUp = () => {
      refScrollUp.current.scrollIntoView({ behavior: "smooth" });
    };
    handleScrollUp();
  }, []);

  return (
    <div className="resume-container" ref={refScrollUp}>
      <div className="header noprint react-reval">
        <div className="header-intro header-intro-resume theme-bg-primary noprint">
          <h2 className="page-heading">{t("resume")}</h2>
          <a
            dir="ltr"
            className="btn btn-primary theme-btn-on-bg download-resume"
            href={CV}
            download="ArkanJbali_CV"
          >
            <i className="fa fa-download" style={{ marginRight: 5 }}></i>
            {t("Download")}
          </a>
        </div>

        {/* <!--//header-intro--> */}
      </div>
      {/* // <!--//header--> */}
      <article dir="ltr" className="resume-wrapper react-reval">
        <div className="resume-wrapper-inner">
          <div className="resume-header">
            <div className="media">
              <div className="media-body">
                <div className="primary-info">
                  <h1 className="name">Arkan Jbali</h1>
                  <div className="title">Full Stack Developer</div>
                  <ul className="list-unstyled">
                    <li>
                      <a href="mailto:arkan.1997@gmail.com">
                        <i
                          style={{ marginRight: 5 }}
                          className="fa fa-envelope fa-fw"
                          data-fa-transform="grow-3"
                        ></i>
                        Arkan.1997@gmail.com
                      </a>
                    </li>
                    <li>
                      <a href="tel:0524033910">
                        <i
                          className="fa fa-phone fa-fw"
                          data-fa-transform="grow-3"
                          style={{ marginRight: 5 }}
                        ></i>
                        +972 0524033910
                      </a>
                    </li>
                  </ul>
                </div>
                {/* <!--//primary-info--> */}
                <div className="secondary-info">
                  <ul className="resume-social list-unstyled">
                    <li>
                      <a
                        href="https://www.linkedin.com/in/arkan-jbali/"
                        rel="noreferrer"
                        target="_blank"
                      >
                        <span className="fa-container">
                          <i className="fa fa-linkedin fa-fw"></i>
                        </span>
                        linkedin.com/in/arkan-jbali
                      </a>
                    </li>
                    <li>
                      <a
                        href="https://github.com/ArkanJbali"
                        rel="noreferrer"
                        target="_blank"
                      >
                        <span className="fa-container">
                          <i className="fa fa-github fa-fw"></i>
                        </span>
                        github.com/ArkanJbali
                      </a>
                    </li>
                    <li>
                      <a
                        href="https://arkanjbali.herokuapp.com"
                        rel="noreferrer"
                        target="_blank"
                      >
                        <span className="fa-container">
                          <i className="fa fa-globe"></i>
                        </span>
                        arkanjbali-herokuapp.com
                      </a>
                    </li>
                  </ul>
                </div>
                {/* <!--//secondary-info--> */}
              </div>
              {/* <!--//media-body--> */}
            </div>
            {/* <!--//media--> */}
          </div>
          <div className="resume-body">
            <section className="resume-section summary-section">
              <h2 className="resume-section-title">Career Summary</h2>
              <div className="resume-section-content">
                <p>
                  Software Developer adept in bringing expertise in design,
                  testing and development of software systems. Equipped with a
                  diverse and promising skill-set. Skilled in various platforms,
                  languages, and frameworks. Experienced with the latest cutting
                  edge development tools and procedures. Able to effectively
                  self-manage during independent projects, as well as
                  collaborate as part of a team.
                </p>
              </div>
            </section>
            {/* <!--//summary-section--> */}
            <div className="row">
              <div className="col-lg-9">
                <section className="resume-section experience-section">
                  <h2 className="resume-section-title">Work Experience</h2>
                  <div className="resume-section-content">
                    <div className="resume-timeline position-relative">
                      <article className="resume-timeline-item position-relative">
                        <div className="resume-timeline-item-header">
                          <div>
                            <h3 className="resume-position-title">
                              Full Stack Developer
                            </h3>
                            <div className="resume-company-name">
                              CODIT Software Solutions
                            </div>
                          </div>
                          {/* <!--//row--> */}
                          <div className="resume-position-time">
                            2021 - Present
                          </div>
                        </div>
                        {/* <!--//resume-timeline-item-header--> */}
                        <div className="resume-timeline-item-desc">
                          <p>
                            • Responsible for web app design and development for
                            various clients' business.
                            <br />
                            • Built web-based applications for small businesses
                            that which include different domains: management,
                            e-Commerce, solutions, and dashboards.
                            <br />
                            • Worked within an agile team and helped prioritize
                            and scope feature requests to ensure that the
                            biggest impact features were worked on first.
                            <br />• Built extensive unit tests coverage for all
                            new features.
                          </p>

                          <h4 className="resume-timeline-item-desc-heading font-weight-bold">
                            Technologies used:
                          </h4>
                          <ul className="list-inline">
                            <li className="list-inline-item">
                              <span className="badge badge-secondary badge-pill">
                                .Net Core
                              </span>
                            </li>
                            <li className="list-inline-item">
                              <span className="badge badge-secondary badge-pill">
                                Node.js
                              </span>
                            </li>
                            <li className="list-inline-item">
                              <span className="badge badge-secondary badge-pill">
                                SQL-Server
                              </span>
                            </li>
                            <li className="list-inline-item">
                              <span className="badge badge-secondary badge-pill">
                                HTML
                              </span>
                            </li>
                            <li className="list-inline-item">
                              <span className="badge badge-secondary badge-pill">
                                CSS/SASS
                              </span>
                            </li>
                            <li className="list-inline-item">
                              <span className="badge badge-secondary badge-pill">
                                JQury/Ajax
                              </span>
                            </li>
                          </ul>
                        </div>
                        {/* <!--//resume-timeline-item-desc--> */}
                      </article>
                      {/* <!--//resume-timeline-item--> */}

                      <article className="resume-timeline-item position-relative">
                        <div className="resume-timeline-item-header">
                          <div>
                            <h3 className="resume-position-title">
                              Automation Tester
                              <small>(Intern)</small>
                            </h3>
                            <div className="resume-company-name">
                              Progineer Technologies
                            </div>
                          </div>
                          {/* <!--//row--> */}
                          <div className="resume-position-time">2019</div>
                        </div>
                        {/* <!--//resume-timeline-item-header--> */}
                        <div className="resume-timeline-item-desc">
                          <p>
                            Practical training as automation tester on the
                            company's website. We implement scripts to automate
                            the website using:
                            <br />
                            Selenium, Junit in Java, build test matrices and
                            test plans.
                          </p>
                          <h4 className="resume-timeline-item-desc-heading font-weight-bold">
                            Technologies used:
                          </h4>
                          <ul className="list-inline">
                            <li className="list-inline-item">
                              <span className="badge badge-secondary badge-pill">
                                Java
                              </span>
                            </li>
                            <li className="list-inline-item">
                              <span className="badge badge-secondary badge-pill">
                                Selenium
                              </span>
                            </li>
                            <li className="list-inline-item">
                              <span className="badge badge-secondary badge-pill">
                                Junit
                              </span>
                            </li>
                          </ul>
                        </div>
                        {/* <!--//resume-timeline-item-desc--> */}
                      </article>
                      {/* <!--//resume-timeline-item--> */}
                    </div>
                    {/* <!--//resume-timeline--> */}
                  </div>
                </section>
                {/* <!--//Courses-section--> */}
                <section className="resume-section experience-section">
                  <h2 className="resume-section-title">Courses</h2>
                  <div className="resume-section-content">
                    <div className="resume-timeline position-relative">
                      <article className="resume-timeline-item position-relative">
                        <div className="resume-timeline-item-header">
                          <div>
                            <h3 className="resume-position-title">
                              NoSQL MongoDB
                            </h3>
                            <div className="resume-company-name">
                              Tsofen تسوفن צופן
                            </div>
                          </div>
                          {/* <!--//row--> */}
                          <div className="resume-position-time">2020</div>
                        </div>
                        {/* <!--//resume-timeline-item-header--> */}
                        <div className="resume-timeline-item-desc">
                          <p>
                            Online training course operated by Tsofen
                            organization with IBM based on their online courses'
                            platform SkillsBuild platform. Totaling 20 academic
                            hours, including: <br />• Self-online course
                            training and tests
                          </p>

                          <h4 className="resume-timeline-item-desc-heading font-weight-bold">
                            Technologies used:
                          </h4>
                          <ul className="list-inline">
                            <li className="list-inline-item">
                              <span className="badge badge-secondary badge-pill">
                                SQL
                              </span>
                            </li>
                            <li className="list-inline-item">
                              <span className="badge badge-secondary badge-pill">
                                NoSQL
                              </span>
                            </li>
                            <li className="list-inline-item">
                              <span className="badge badge-secondary badge-pill">
                                MongoDB
                              </span>
                            </li>
                            <li className="list-inline-item">
                              <span className="badge badge-secondary badge-pill">
                                Python/Django
                              </span>
                            </li>
                          </ul>
                        </div>
                        {/* <!--//resume-timeline-item-desc--> */}
                      </article>
                      {/* <!--//resume-timeline-item--> */}

                      <article className="resume-timeline-item position-relative">
                        <div className="resume-timeline-item-header">
                          <div>
                            <h3 className="resume-position-title">
                              Full Stack Developer
                              <small>(Practical Training)</small>
                            </h3>
                            <div className="resume-company-name">
                              Tsofen تسوفن צופן
                            </div>
                          </div>
                          {/* <!--//row--> */}
                          <div className="resume-position-time">2018</div>
                        </div>
                        {/* <!--//resume-timeline-item-header--> */}
                        <div className="resume-timeline-item-desc">
                          <p>
                            Practical training course operated by Tsofen
                            organization, in cooperation with Amdocs and IITC
                            totaling 250 academic hours.
                            <br />
                            • Theoretical contents and a hands-on development
                            project using industry methodologies and practices.
                            <br />• Project, in collaboration with Amdocs:
                            Monitoring and control system that identifies
                            defects and their severity on Amdocs systems logs
                            for their technical support team internal usage.
                          </p>
                          <h4 className="resume-timeline-item-desc-heading font-weight-bold">
                            Technologies used:
                          </h4>
                          <ul className="list-inline">
                            <li className="list-inline-item">
                              <span className="badge badge-secondary badge-pill">
                                Java
                              </span>
                            </li>
                            <li className="list-inline-item">
                              <span className="badge badge-secondary badge-pill">
                                MySQL
                              </span>
                            </li>
                            <li className="list-inline-item">
                              <span className="badge badge-secondary badge-pill">
                                Angular
                              </span>
                            </li>
                            <li className="list-inline-item">
                              <span className="badge badge-secondary badge-pill">
                                JS, HTML/CSS
                              </span>
                            </li>
                            <li className="list-inline-item">
                              <span className="badge badge-secondary badge-pill">
                                Agile methodology
                              </span>
                            </li>
                          </ul>
                        </div>
                        {/* <!--//resume-timeline-item-desc--> */}
                      </article>
                      {/* <!--//resume-timeline-item--> */}
                    </div>
                    {/* <!--//resume-timeline--> */}
                  </div>
                </section>
                {/* <!--//Course-section--> */}
              </div>
              <div className="col-lg-3">
                <section className="resume-section skills-section">
                  <h2 className="resume-section-title">Skills &amp; Tools</h2>
                  <div className="resume-section-content">
                    <div className="resume-skill-item">
                      <h4 className="resume-skills-cat font-weight-bold">
                        Frontend
                      </h4>
                      <ul className="list-unstyled mb-4">
                        <li>
                          <div className="resume-skill-name">React Native</div>
                          <div className="progress resume-progress">
                            <div
                              className="progress-bar theme-progress-bar-dark"
                              role="progressbar"
                              style={{ width: "95%" }}
                              aria-valuenow="25"
                              aria-valuemin="0"
                              aria-valuemax="100"
                            ></div>
                          </div>
                        </li>
                        <li>
                          <div className="resume-skill-name">Angular</div>
                          <div className="progress resume-progress">
                            <div
                              className="progress-bar theme-progress-bar-dark"
                              role="progressbar"
                              style={{ width: "80%" }}
                              aria-valuenow="25"
                              aria-valuemin="0"
                              aria-valuemax="100"
                            ></div>
                          </div>
                        </li>

                        <li>
                          <div className="resume-skill-name">Node.js</div>
                          <div className="progress resume-progress">
                            <div
                              className="progress-bar theme-progress-bar-dark"
                              role="progressbar"
                              style={{ width: "92%" }}
                              aria-valuenow="25"
                              aria-valuemin="0"
                              aria-valuemax="100"
                            ></div>
                          </div>
                        </li>
                        <li>
                          <div className="resume-skill-name">
                            HTML/JS/CSS/SASS
                          </div>
                          <div className="progress resume-progress">
                            <div
                              className="progress-bar theme-progress-bar-dark"
                              role="progressbar"
                              style={{ width: "96%" }}
                              aria-valuenow="25"
                              aria-valuemin="0"
                              aria-valuemax="100"
                            ></div>
                          </div>
                        </li>
                      </ul>
                    </div>
                    {/* <!--//resume-skill-item--> */}

                    <div className="resume-skill-item">
                      <h4 className="resume-skills-cat font-weight-bold">
                        Backend
                      </h4>
                      <ul className="list-unstyled">
                        <li>
                          <div className="resume-skill-name">.Net Core</div>
                          <div className="progress resume-progress">
                            <div
                              className="progress-bar theme-progress-bar-dark"
                              role="progressbar"
                              style={{ width: "95%" }}
                              aria-valuenow="25"
                              aria-valuemin="0"
                              aria-valuemax="100"
                            ></div>
                          </div>
                        </li>
                        <li>
                          <div className="resume-skill-name">Python/Django</div>
                          <div className="progress resume-progress">
                            <div
                              className="progress-bar theme-progress-bar-dark"
                              role="progressbar"
                              style={{ width: "90%" }}
                              aria-valuenow="25"
                              aria-valuemin="0"
                              aria-valuemax="100"
                            ></div>
                          </div>
                        </li>

                        <li>
                          <div className="resume-skill-name">Java</div>
                          <div className="progress resume-progress">
                            <div
                              className="progress-bar theme-progress-bar-dark"
                              role="progressbar"
                              style={{ width: "86%" }}
                              aria-valuenow="25"
                              aria-valuemin="0"
                              aria-valuemax="100"
                            ></div>
                          </div>
                        </li>
                      </ul>
                    </div>
                    {/* <!--//resume-skill-item--> */}

                    <div className="resume-skill-item">
                      <h4 className="resume-skills-cat font-weight-bold">
                        Others
                      </h4>
                      <ul className="list-inline">
                        <li className="list-inline-item">
                          <span className="badge badge-light">
                            Mobile Development
                          </span>
                        </li>
                        <li className="list-inline-item">
                          <span className="badge badge-light">Code Review</span>
                        </li>
                        <li className="list-inline-item">
                          <span className="badge badge-light">
                            Unit Testing
                          </span>
                        </li>
                        <li className="list-inline-item">
                          <span className="badge badge-light">Management</span>
                        </li>
                        <li className="list-inline-item">
                          <span className="badge badge-light">Git</span>
                        </li>
                        <li className="list-inline-item">
                          <span className="badge badge-light">Agile</span>
                        </li>
                        <li className="list-inline-item">
                          <span className="badge badge-light">ML</span>
                        </li>
                        <li className="list-inline-item">
                          <span className="badge badge-light">
                            Data Analysis
                          </span>
                        </li>
                        <li className="list-inline-item">
                          <span className="badge badge-light">
                            Wireframing / AdobeXD
                          </span>
                        </li>
                      </ul>
                    </div>
                    {/* <!--//resume-skill-item--> */}
                  </div>
                  {/* <!--resume-section-content--> */}
                </section>
                {/* <!--//skills-section--> */}
                <section className="resume-section education-section">
                  <h2 className="resume-section-title">Education</h2>
                  <div className="resume-section-content">
                    <ul className="list-unstyled">
                      <li>
                        <div className="resume-degree font-weight-bold">
                          BSc Computer Science
                        </div>
                        <div className="resume-degree-org">
                          Arab American University
                        </div>
                        <div className="resume-degree-time">2016 - 2020</div>
                      </li>
                    </ul>
                  </div>
                </section>
                {/* <!--//education-section--> */}
                <section className="resume-section reference-section">
                  <h2 className="resume-section-title">Awards</h2>
                  <div className="resume-section-content">
                    <ul className="list-unstyled resume-awards-list">
                      <li className="resume-award-wrapper position-relative">
                        <i
                          className="resume-award-icon fa fa-trophy"
                          data-fa-transform="shrink-2"
                        ></i>
                        <div className="resume-award-name">
                          Award President's Honor
                        </div>
                        <div className="resume-award-desc">
                          University President's Honor 2018/2019.
                        </div>
                      </li>
                    </ul>
                  </div>
                </section>
                {/* <!--//interests-section--> */}
                <section className="resume-section language-section">
                  <h2 className="resume-section-title">Language</h2>
                  <div className="resume-section-content">
                    <ul className="list-unstyled resume-lang-list">
                      <li>
                        <span className="resume-lang-name font-weight-bold">
                          Arabic
                        </span>
                        <small className="text-muted font-weight-normal">
                          (Native)
                        </small>
                      </li>
                      <li className="align-middle">
                        <span className="resume-lang-name font-weight-bold">
                          Hebrew
                        </span>
                        <small className="text-muted font-weight-normal">
                          (Professional)
                        </small>
                      </li>
                      <li>
                        <span className="resume-lang-name font-weight-bold">
                          English
                        </span>
                        <small className="text-muted font-weight-normal">
                          (Professional)
                        </small>
                      </li>
                    </ul>
                  </div>
                </section>
                {/* <!--//language-section--> */}
                <section className="resume-section interests-section">
                  <h2 className="resume-section-title">Volunteering</h2>
                  <div className="resume-section-content">
                    <ul className="list-unstyled position-relative">
                      <li className="resume-award-wrapper position-relative">
                        <i
                          className="resume-award-icon fa fa-gratipay"
                          data-fa-transform="shrink-2"
                        ></i>
                        <div className="resume-award-name">
                          Tsofen Hackathon
                        </div>
                        <div className="resume-award-desc">
                          Mentor for High school students, Tsofen Hackathon
                          2021.
                        </div>
                      </li>
                    </ul>
                    <ul className="list-unstyled position-relative">
                      <li className="resume-award-wrapper position-relative">
                        <i
                          className="resume-award-icon fa fa-gratipay"
                          data-fa-transform="shrink-2"
                        ></i>
                        <div className="resume-award-name">Mifal Hapayis</div>
                        <div className="resume-award-desc">
                          Educational youth program, instructors' coordinator.
                        </div>
                      </li>
                    </ul>
                  </div>
                </section>
                {/* <!--//interests-section--> */}
              </div>
            </div>
            {/* <!--//row--> */}
          </div>
          {/* <!--//resume-body--> */}
        </div>
      </article>
    </div>
  );
};

export default ResumeComponent;
